import React from 'react'

import styles from './hamburger.module.css'

const HamburgerMenu = (props) => (
  <button onClick={props.onClick} className={
    [
      styles.hamburger,
      styles.hamburgerCollapse,
      (props.active ? styles.isActive : '')
    ].join(' ')}
  >
    <div className={styles.hamburgerBox}>
      <div className={styles.hamburgerInner}/>
    </div>
  </button>
)

export default HamburgerMenu
