import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import logo from '../images/sitelogo.svg'
import logoLight from '../images/sitelogo_light.svg'
import HamburgerMenu from './HamburgerMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Root = styled.header`
  height: 60px;
  box-shadow: var(--shadow-on-bg);
  position: fixed;
  width: 100%;
  padding: 0 7px 0 10px;
  z-index: 1000;
  @media (min-width: 768px) {
    padding: 0 15px;
  }
`

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid transparent;
  @media (min-width: 992px) {
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
  }
`

const BG = styled.div`
  background: var(--navbar-bg);
  border-bottom: 3px solid var(--accent);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Brand = styled(Link)`
  height: 100%;
  font-size: 25px;
  font-weight: 400;
  text-decoration: none;
  color: var(--text);
  display: flex;
  align-items: center;
  z-index: 10;
  &>img {
    vertical-align: top;
    height: 80%;
    margin-right: 10px;
  }
`

const Links = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background: var(--navbar-bg);
  transform: translateY(-220px); /* 55px (each link item) * 4 (current item amount) = 220px */
  transition: transform .2s ease;
  z-index: 0;
  &.open {
    transform: translateY(0);
    box-shadow: var(--shadow-on-bg);
  }
  @media (min-width: 768px) {
    background: transparent;
    flex-direction: row;
    justify-content: flex-end;
    position: initial;
    transform: none;
    transition: none;
    z-index: 20;
  }
`

const NavLink = styled(Link)`
  color: var(--text);
  font-weight: 300;
  font-size: 20px;
  text-decoration: none;
  padding: 16px 12px 16px 20px;
  background: var(--navbar-bg);
  &::after {
    display: block;
    content: attr(title);
    font-weight: 500;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &.active {
    background: var(--accent);
    font-weight: 500;
    color: #fff;
  }
  @media (min-width: 768px) {
    padding: 18px 20px;
  }
`

const ThemeButton = styled.button`
  font-size: 30px;
  background: transparent;
  border: none;
  color: var(--text);
  cursor: pointer;
  padding: 0 20px 0 0;
  position: absolute;
  right: 63px;
  height: 60px;
  z-index: 1;
  @media (min-width: 768px) {
    position: static;
    z-index: 20;
    padding: 0 0 0 20px;
    font-size: 25px;
  }
`

function Header(props) {
  const [menuStatus, setMenuStatus] = useState(false)

  function toggleMenu() {
    setMenuStatus(!menuStatus)
  }

  return (
    <Root>
      <Container>
        <Brand to="/" className="brand-logo">
          <img src={props.isDark ? logo : logoLight} alt="Site logo"/>lorenzopr.
        </Brand>

        <HamburgerMenu onClick={toggleMenu} active={menuStatus}/>

        <Links className={(menuStatus ? 'open' : '')}>
          <NavLink className="hvr-top" to="/" activeClassName="active" title="Home">Home</NavLink>
          <NavLink className="hvr-top" to="/about" activeClassName="active" title="About Me">About Me</NavLink>
          <NavLink className="hvr-top" to="/projects" activeClassName="active" partiallyActive={true} title="Projects">Projects</NavLink>
          <NavLink className="hvr-top" to="/contact" activeClassName="active" title="Contact Me">Contact Me</NavLink>
        </Links>
        <ThemeButton onClick={() => props.toggleTheme(!props.isDark ? 'dark' : 'light')}><FontAwesomeIcon
          icon="adjust" fixedWidth/></ThemeButton>
      </Container>
      <BG/>
    </Root>
  );
}

export default Header
