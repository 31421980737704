import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby';

import FullpageLayout from '../components/FullpageLayout'
import Logo3D from '../components/Logo3D'
import Button from '../components/Button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  @media (min-width: 992px) {
    height: 80%;
  }
`

const Title = styled.h1`
  font-size: 50px;
  font-weight: 300;
  color: var(--text);
  margin: 0;
  padding: 10px 0;
  &>strong {
    font-weight: 500;
  }
  @media (max-width: 350px), (max-height: 680px) {
    font-size: 35px;
  }
  @media (min-width: 768px) {
    font-size: 60px;
    text-align: center;
  }
  @media (min-width: 992px) {
    font-size: 80px;
    text-align: left;
    width: calc(100% - 450px);
  }
`

const Description = styled.p`
  padding: 10px 0;
  width: 60%;
  text-align: right;
  font-size: 30px;
  margin: 0 0 0 auto;
  @media (max-width: 350px), (max-height: 680px) {
    font-size: 20px;
  }
  @media (min-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 40px;
  } 
  @media (min-width: 992px) {
    font-size: 50px;
    text-align: left;
    width: 350px;
    margin-left: 0;
  }
`

const ButtonWrapper = styled(Button)`
  @media (min-width: 768px) {
    margin: 0 auto;
  }
  @media (min-width: 992px) {
    margin: 0 auto 0 0;
  }
`

const Logo3DWrapper = styled(Logo3D)`
  @media (min-width: 992px) {
    display: inline;
    position: absolute;
    right: 0;
    width: 417px;
  }
`

const IndexPage = () => (
  <FullpageLayout>
    <Container>
      <Title><strong>L</strong>orenzo <strong>P</strong>inna <strong>R</strong>odríguez</Title>
      <Logo3DWrapper/>
      <Description>Web & Mobile developer.</Description>
      <ButtonWrapper onClick={() => navigate('/about')}>More about me</ButtonWrapper>
    </Container>
  </FullpageLayout>
)

export default IndexPage
