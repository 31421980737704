import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Header from './Header'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const Container = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
`

const FullpageLayout = ({ children }) => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <>
        <Helmet
          title="lorenzopr."
          meta={[
            {
              name: 'description',
              content: 'Lorenzo Pinna Rodríguez, junior Web & Mobile developer from Gran Canaria.',
            },
            {
              name: 'keywords',
              content: 'web, mobile, android, ios, apple, google, sdk, java, javascript, php, gran, canaria, canarias, las palmas, palmas, junior, desarrollador, desarrollo, pagina, página, paginas, páginas, fullstack, angular, vue, react, portafolio, curriculum, trabajo, work, cv',
            },
          ]}>
          <html lang="en" className="landing"/>
        </Helmet>
        <Header toggleTheme={toggleTheme} isDark={theme === 'dark'}/>
        <Container>
          {children}
        </Container>
      </>
    )}
  </ThemeToggler>
)

FullpageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullpageLayout
